import { Auth } from "aws-amplify";
import React, { useEffect } from "react";
// import { useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiGetProfile, apiGetSSOtoken } from "../Api";
import BodyWrapper from "../components/Styled/BodyWrapper";
import Layout from '../components/Global/Layout'
import Seo from '../components/Global/Seo'

const imgixurl = 'http://joto.imgix.net';

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const Sso = () => {
  // let query = useQuery();
  let {
    user: { attributes },
  } = useSelector((state) => state.authentication);


  const getCreds = async() => {
    let credentials = await Auth.currentUserCredentials();
    return credentials;
  }

  useEffect(() => {
    // let sso = query.get("sso");
    // let sig = query.get("sig");
    let sso;
    let sig;

    let profile = {};

    apiGetProfile()
    .then(userProfile => {
      profile = userProfile;
      return getCreds();
    }).then(creds => {

      let avatar_url = `${imgixurl}/protected/${encodeURIComponent(creds._identityId)}/${profile.image}?w=400&h=400&fit=crop`

      let data = {
        sso,
        sig,
        firstname: attributes["custom:firstname"],
        lastname: attributes["custom:lastname"],
        email: attributes["email"],
        sub: attributes["sub"],
        avatar_url,
        avatar_force_update: true,
        username: profile.handle,
        bio: profile.bio
      };
      return data;
    }).then(data => apiGetSSOtoken(data))
    .then(response =>{  
      window.location.replace(response.message)
    })
    .catch((err) => console.log(err));
  }, []);

  return (
    <Layout>
    <Seo />
    <BodyWrapper>
      <h3>Redirecting you back to the community forum...</h3>
    </BodyWrapper>
    </Layout>
  );
};

export default Sso;
